// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PLjLJxjEp", "gG0d27hlF", "BWLdux_ky", "d9ZKqny2W", "dXgxXgiqi", "WIYxRlBP_", "gW5NBmn7r", "wTn7Z_Jup", "QMjOJUDs7"];

const serializationHash = "framer-nPf5y"

const variantClassNames = {BWLdux_ky: "framer-v-1a6icqj", d9ZKqny2W: "framer-v-12m2spm", dXgxXgiqi: "framer-v-169ng0j", gG0d27hlF: "framer-v-1e17ddb", gW5NBmn7r: "framer-v-15jlzsr", PLjLJxjEp: "framer-v-1y0pfau", QMjOJUDs7: "framer-v-oqkxer", WIYxRlBP_: "framer-v-1823hfh", wTn7Z_Jup: "framer-v-195if3a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {d9ZKqny2W: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}, default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "WIYxRlBP_", "Image 16:9": "d9ZKqny2W", "Image 3:2": "BWLdux_ky", "Image 4:3": "gG0d27hlF", "Image 4:5": "dXgxXgiqi", "Variant 7": "gW5NBmn7r", "Variant 8": "wTn7Z_Jup", "Variant 9": "QMjOJUDs7", Default: "PLjLJxjEp"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "PLjLJxjEp", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PLjLJxjEp", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1y0pfau", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"PLjLJxjEp"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({BWLdux_ky: {"data-framer-name": "Image 3:2"}, d9ZKqny2W: {"data-framer-name": "Image 16:9"}, dXgxXgiqi: {"data-framer-name": "Image 4:5"}, gG0d27hlF: {"data-framer-name": "Image 4:3"}, gW5NBmn7r: {"data-framer-name": "Variant 7"}, QMjOJUDs7: {"data-framer-name": "Variant 9"}, WIYxRlBP_: {"data-framer-name": "Image 1:1"}, wTn7Z_Jup: {"data-framer-name": "Variant 8"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-nPf5y[data-border=\"true\"]::after, .framer-nPf5y [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nPf5y.framer-bcud7k, .framer-nPf5y .framer-bcud7k { display: block; }", ".framer-nPf5y.framer-1y0pfau { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-nPf5y.framer-v-1e17ddb.framer-1y0pfau { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-nPf5y.framer-v-1a6icqj.framer-1y0pfau { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-nPf5y.framer-v-12m2spm.framer-1y0pfau { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-nPf5y.framer-v-169ng0j.framer-1y0pfau { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-nPf5y.framer-v-1823hfh.framer-1y0pfau { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-nPf5y.framer-v-15jlzsr.framer-1y0pfau { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-nPf5y.framer-v-195if3a.framer-1y0pfau, .framer-nPf5y.framer-v-oqkxer.framer-1y0pfau { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"gG0d27hlF":{"layout":["fixed","fixed"]},"BWLdux_ky":{"layout":["fixed","fixed"]},"d9ZKqny2W":{"layout":["fixed","fixed"]},"dXgxXgiqi":{"layout":["fixed","fixed"]},"WIYxRlBP_":{"layout":["fixed","fixed"]},"gW5NBmn7r":{"layout":["fixed","fixed"]},"wTn7Z_Jup":{"layout":["fixed","fixed"]},"QMjOJUDs7":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerQdOKfpTpJ: React.ComponentType<Props> = withCSS(Component, css, "framer-nPf5y") as typeof Component;
export default FramerQdOKfpTpJ;

FramerQdOKfpTpJ.displayName = "Image / Aspect Ratio Copy 3";

FramerQdOKfpTpJ.defaultProps = {height: 256, width: 256};

addPropertyControls(FramerQdOKfpTpJ, {variant: {options: ["PLjLJxjEp", "gG0d27hlF", "BWLdux_ky", "d9ZKqny2W", "dXgxXgiqi", "WIYxRlBP_", "gW5NBmn7r", "wTn7Z_Jup", "QMjOJUDs7"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerQdOKfpTpJ, [])